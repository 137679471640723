import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useScrollYPosition } from 'react-use-scroll-position';

// import Headroom from 'react-headroom';

import { vars, IconMenu } from '../lib/theme';
import { LATEST_MASTERCLASS_QUERY } from '../lib/graphql';

import useUser from './useUser';

import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';
import NavUserInfo from './NavUserInfo';
import Button from './Button';

const SiteHeader = () => {
  const user = useUser();
  const router = useRouter();
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [isFullBleed, setIsFullBleed] = React.useState(router.route === '/' || router.route === '/pro');
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const { data } = useQuery(LATEST_MASTERCLASS_QUERY);
  const scrollY = useScrollYPosition();

  React.useEffect(() => {
    process.browser &&
      router.events.on('routeChangeComplete', () => {
        if (mobileNavOpen) () => setMobileNavOpen(false);
        if (router.route === '/' || router.route === '/pro') () => setisFullBleed(true);
      });
  }, [mobileNavOpen, router.events, router.route]);

  React.useEffect(() => {
    if (scrollY > 10) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  }, [scrollY]);

  return (
    <Root isFullBleed={isFullBleed} hasScrolled={hasScrolled} mobileNavOpen={mobileNavOpen}>
      {data?.latestMasterclass.isLive && (
        <Link href="/masterclasses">
          <a>
            <Alert>[LIVE MASTERCLASS] Click here to join!</Alert>
          </a>
        </Link>
      )}
      {/* <Headroom> */}
      <Main>
        <Inner>
          <MobileOnly>
            <Button
              css={css`
                color: ${vars.colors.white};
                padding: ${vars.spacing[1]};
                margin-right: ${vars.spacing[1]};
                &:hover {
                  color: ${vars.colors.secondary.offwhite};
                }
              `}
              theme="textOnly"
              onClick={() => setMobileNavOpen(prev => !prev)}
              ariaLabel="Menu toggle"
            >
              <IconMenu size={20} />
            </Button>
            <NavMobile isOpen={mobileNavOpen} setIsOpen={setMobileNavOpen} />
          </MobileOnly>
          <Logo>
            <Link href="/">
              <a>
                <img src="/static/logo-school-of-mcrock.svg" alt="School of McRock" />
              </a>
            </Link>
          </Logo>
          <DesktopOnly>
            <NavDesktop />
          </DesktopOnly>
          <div
            css={css`
              margin-left: auto;
            `}
          >
            {user ? (
              <NavUserInfo />
            ) : (
              <span
                css={css`
                  @media (min-width: 620px) {
                    display: none;
                  }
                `}
              >
                <Link href="/free-guitar-toolkit">
                  <a>
                    <Button
                      theme="success"
                      css={css`
                        padding: ${vars.spacing[0]} ${vars.spacing[1]};
                        font-size: 0.7em;
                      `}
                    >
                      Free toolkit
                    </Button>
                  </a>
                </Link>
              </span>
            )}
          </div>
        </Inner>
      </Main>
      {/* </Headroom> */}
    </Root>
  );
};

export default SiteHeader;

// Styles

const Alert = styled('section')`
  background-color: ${vars.colors.warning.mid};
  color: ${vars.colors.white};
  padding: ${vars.spacing[0]} ${vars.spacing[1]};
  text-align: center;
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;
  &:hover {
    background-color: ${vars.colors.warning.light};
  }
`;

const Root = styled('header')`
  position: static;
  z-index: 20;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  /* background: linear-gradient(15deg, ${vars.colors.secondary.darkest}, ${vars.colors.primary.darkest}); */
  background-color: ${vars.colors.secondary.darkest};
  box-shadow: ${vars.shadows[0]};
  transition: all 0.3s ease-in-out;

  ${({ isFullBleed, hasScrolled }) =>
    isFullBleed &&
    css`
      margin-bottom: -70px;
    `}
  ${({ isFullBleed, hasScrolled, mobileNavOpen }) =>
    isFullBleed &&
    !hasScrolled &&
    !mobileNavOpen &&
    css`
      background: transparent;
      box-shadow: none;
    `}
`;

const Main = styled('div')`
  /* background: linear-gradient(15deg, ${vars.colors.secondary.darkest}, ${vars.colors.primary.darkest});
  background-color: ${vars.colors.secondary.darkest}; */
`;

const Inner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: ${vars.spacing[0]} ${vars.spacing[1]} ${vars.spacing[0]} 0;
  /* max-width: ${vars.break.xxl}; */
  margin-left: auto;
  margin-right: auto;

  /* box-shadow: ${vars.shadows[0]}; */
  color: ${vars.colors.grey.lightest};

  @media screen and (min-width: 375px) {
    padding: ${vars.spacing[1]};
  }
  @media (min-width: 1180px) {
    /* padding: ${vars.spacing[2]} ${vars.spacing[1]}; */
  }
`;

const Logo = styled('div')`
  display: block;
  flex: 0 1 140px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  @media screen and (min-width: 350px) {
    flex: 0 1 180px;
  }
  @media screen and (min-width: 430px) {
    flex: 0 1 240px;
  }
  @media screen and (min-width: ${vars.break.sm}) {
    /* flex: 0 1 300px; */
  }
`;

const MobileOnly = styled('div')`
  display: block;
  @media screen and (min-width: ${vars.break.sm}) {
    margin-right: ${vars.spacing[1]};
  }
  @media (min-width: 1180px) {
    display: none;
  }
`;

const DesktopOnly = styled('div')`
  margin-left: ${vars.spacing[1]};
  flex: 1 1 auto;
  display: none;
  @media (min-width: 620px) {
    display: block;
  }
`;
