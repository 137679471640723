import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import {
  vars,
  IconMusic,
  IconGuitar,
  IconLive,
  IconUsers,
  IconSettings,
  IconBag,
} from '../lib/theme';

import useUser from './useUser';

import Button from './Button';
import MasterclassNavItem from './MasterclassNavItem';

const NavDesktop = ({ className }) => {
  const user = useUser();
  const hasActiveSubscription = user?.hasActiveSubscription;

  return (
    <Root className={className}>
      <Main>
        <NavItem>
          <Link href="/lessons">
            <a>
              <IconGuitar />
              <span>Lessons</span>
            </a>
          </Link>
        </NavItem>
        <NavItem>
          <Link href="/tracks">
            <a>
              <IconMusic />
              <span>Tracks</span>
            </a>
          </Link>
        </NavItem>
        {/* <NavItem>
          <Link href="/masterclasses">
            <a>
              <MasterclassNavItem />
            </a>
          </Link>
        </NavItem> */}
        <NavItem>
          <Link href="/masterclasses">
            <a>
              <IconLive />
              <span>Masterclasses</span>
            </a>
          </Link>
        </NavItem>
        {/* <NavItem>
          <Link href="/community">
            <a>
              <IconUsers />
              <span>Community</span>
            </a>
          </Link>
        </NavItem> */}
        <NavItem>
          <Link href={!user ? '/free-guitar-toolkit' : '/R5NXwtRTff2h7EQk'}>
            <a>
              <IconSettings />
              <span
                css={css`
                  position: relative;
                `}
              >
                Free Toolkit
              </span>
            </a>
          </Link>
        </NavItem>
        <NavItem>
          <Link href="/store">
            <a>
              <IconBag />
              <span>Store</span>
            </a>
          </Link>
        </NavItem>
        <NavItem>
          <Link href="/pro">
            <a>
              <div
                css={css`
                  color: ${vars.colors.white};
                  background: linear-gradient(
                    40deg,
                    ${vars.colors.primary.mid} 15%,
                    ${vars.colors.primary.dark} 85%
                  );
                  padding: 0.4em;
                  border-radius: 0.3em;
                  margin-left: 0.1em;
                  line-height: 1;
                `}
              >
                Pro
              </div>
            </a>
          </Link>
        </NavItem>
        {/* <NavItem>
          <Link href="/giveaway">
            <a>Free Gear</a>
          </Link>
        </NavItem> */}
        {/* {!hasActiveSubscription && (
          <NavItem>
            <Link href="/pricing">
              <a>Pricing</a>
            </Link>
          </NavItem>
        )} */}
      </Main>
      <Sub>
        {!user && (
          <>
            <NavItem>
              <Link href="/login">
                <a>Log In</a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/join">
                <a>
                  <Button theme="success" slim>
                    Join
                  </Button>
                </a>
              </Link>
            </NavItem>
          </>
        )}
      </Sub>
    </Root>
  );
};

export default NavDesktop;

// Styles
const Root = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Main = styled('ul')`
  align-items: center;
  margin: 0 auto 0 0;
  padding: 0;
  list-style-type: none;
  margin-left: ${vars.spacing[2]};
  font-size: 16px;
  & > li {
    margin-left: 1.4em;
    &:first-of-type {
      margin-left: 0;
    }
  }
  & > li a {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.06em;
    color: ${vars.colors.secondary.offwhite};
    i {
      margin-right: 0.6em;
    }
    span {
      position: relative;
      display: inline-block;
      padding-top: ${vars.spacing[0]};
      padding-bottom: ${vars.spacing[0]};

      &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: ${vars.colors.white};
        opacity: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: all 0.4s ease-in-out;
      }
    }
    &:hover {
      color: ${vars.colors.white};
      span:after {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }
  display: none;
  @media (min-width: 1180px) {
    display: flex;
  }
`;

const Sub = styled('ul')`
  align-items: center;
  margin: 0 0 0 auto;
  padding: 0;
  list-style-type: none;
  & > li {
    margin-left: ${vars.spacing[2]};
  }
  & > li a {
    font-weight: 500;
  }
  display: none;
  @media (min-width: 620px) {
    display: flex;
  }
`;

const NavItem = styled('li')`
  & > a {
    text-decoration: none;
    color: ${vars.colors.white};
    transition: color 0.2s ease;
    &:hover {
      color: ${vars.colors.white};
    }
    &.highlight {
      color: ${vars.colors.primary.light};
      &:hover {
        color: ${vars.colors.white};
      }
    }
  }
`;
