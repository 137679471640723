import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Portal from '@reach/portal';
import { motion, AnimatePresence } from 'framer-motion';

import {
  vars,
  IconMusic,
  IconGuitar,
  IconLive,
  IconUsers,
  IconSettings,
  IconBag,
} from '../lib/theme';

import useUser from './useUser';

import Button from './Button';
// import Tag from './Tag';

const NavMobile = ({ isOpen, className }) => {
  const user = useUser();
  const hasActiveSubscription = user?.hasActiveSubscription;

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <Root
            className={className}
            transition={{ type: 'spring', damping: 100, stiffness: 100 }}
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
          >
            <NavItem>
              <Link href="/lessons">
                <a>
                  <IconGuitar />
                  <span>Lessons</span>
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/tracks">
                <a>
                  <IconMusic />
                  <span>Tracks</span>
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/masterclasses">
                <a>
                  <IconLive />
                  <span>Masterclasses</span>
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/community">
                <a>
                  <IconUsers />
                  <span>Community</span>
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href={!user ? '/free-guitar-toolkit' : '/R5NXwtRTff2h7EQk'}>
                <a>
                  <IconSettings />
                  <span
                    css={css`
                      position: relative;
                    `}
                  >
                    Free Toolkit
                  </span>
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/store">
                <a>
                  <IconBag />
                  <span>Store</span>
                </a>
              </Link>
            </NavItem>
            <li
              css={css`
                padding: ${vars.spacing[1]};
              `}
            >
              <Link href="/pro">
                <a>
                  <Button block>Pro</Button>
                </a>
              </Link>
            </li>
            {/* <NavItem>
              <Link href="/giveaway">
                <a>Free Gear</a>
              </Link>
            </NavItem> */}
            {/* {!hasActiveSubscription && (
              <NavItem>
                <Link href="/pricing">
                  <a>Pricing</a>
                </Link>
              </NavItem>
            )} */}
            {user ? (
              <li
                css={css`
                  padding: ${vars.spacing[1]};
                `}
              >
                <Link href="/members">
                  <a>
                    <Button slim block>
                      Members Area
                    </Button>
                  </a>
                </Link>
              </li>
            ) : (
              <>
                <li
                  css={css`
                    padding: ${vars.spacing[1]};
                  `}
                >
                  <Link href="/join">
                    <a>
                      <Button theme="success" block>
                        Join
                      </Button>
                    </a>
                  </Link>
                </li>
                <li
                  css={css`
                    padding: ${vars.spacing[1]};
                  `}
                >
                  <Link href="/login">
                    <a>
                      <Button
                        css={css`
                          color: ${vars.colors.white};
                        `}
                        theme="textOnly"
                        block
                      >
                        Log In
                      </Button>
                    </a>
                  </Link>
                </li>
              </>
            )}
          </Root>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default NavMobile;

// Styles

const Root = styled(motion.ul)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  width: 240px;
  background: linear-gradient(15deg, ${vars.colors.secondary.darkest}, ${vars.colors.secondary.darker});
  box-shadow: ${vars.shadows[0]};
  flex: 0 0 100%;
  text-align: left;
  margin: 0;
  list-style-type: none;
  /* padding: ${vars.spacing[0]}; */
  padding: 0;
  padding-top:60px;
`;

const NavItem = styled('li')`
  & > a {
    display: block;
    text-decoration: none;
    font-family: ${vars.fonts.headings};
    padding: ${vars.spacing[1]} ${vars.spacing[2]};
    color: ${vars.colors.secondary.offwhite};
    transition: all 0.2s ease;
    span {
      position: relative;
      display: inline-block;
      margin-left: ${vars.spacing[1]};
    }
    &:hover {
      color: ${vars.colors.white};
      background-color: ${vars.colors.secondary.dark};
    }
  }
`;
