import React from 'react';
import Link from 'next/link';
import Router from 'next/router';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { vars } from '../lib/theme';
import useUser from './useUser';

import useAuth from './useAuth';
import Button from './Button';
import Tag from './Tag';
import useOnClickOutside from './useOnClickOutside';

const NavUserInfo = ({ className }) => {
  const [navOpen, setNavOpen] = React.useState(false);
  const ref = React.useRef();
  const auth = useAuth();
  const user = useUser();

  useOnClickOutside(ref, () => setNavOpen(false));

  React.useEffect(() => {
    process.browser &&
      Router.events.on('routeChangeComplete', () => {
        if (navOpen) () => setNavOpen(false);
      });
  }, []);

  if (!user) return null;

  return (
    <Root className={className} ref={ref}>
      <Button
        css={css`
          color: ${vars.colors.white};
          padding: ${vars.spacing[1]};
          /* padding-right: ${vars.spacing[0]}; */
          &:hover {
            cursor: pointer;
            color: ${vars.colors.secondary.offwhite};
          }
        `}
        theme="textOnly"
        slim
        onClick={() => setNavOpen((prev) => !prev)}
      >
        {/* <IconUser size={20} /> */}
        <Arrow
          css={css`
            /* margin-left: ${vars.spacing[1]}; */
          `}
          direction="down"
        />
      </Button>
      <AnimatePresence>
        {navOpen && (
          <Menu transition={{ type: 'spring', damping: 100, stiffness: 100 }} initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 0, opacity: 0 }}>
            <li
              css={css`
                margin-bottom: ${vars.spacing[1]};
                font-size: 0.8rem;
                line-height: 1.2;
              `}
            >
              Signed in as
              <br />
              <b>{user.email}</b>
            </li>
            <li
              css={css`
                margin-bottom: ${vars.spacing[1]};
                font-size: 0.8rem;
                line-height: 1.2;
              `}
            >
              {user.hasActiveSubscription && <Tag theme="success">Member</Tag>}
              {/* {user.hasTrialSubscription && <Tag theme="primary">Free Trial</Tag>} */}
              {/* <SubscriptionStatusTag status={user?.subscription?.status} /> */}
            </li>
            {user.hasActiveSubscription && (
              <li>
                <Link href="/members">
                  <a>Members Area</a>
                </Link>
              </li>
            )}
            <li
              css={css`
                font-size: 0.8rem;
              `}
            >
              <Link href="/account/settings">
                <a>Account Settings</a>
              </Link>
            </li>
            <li
              css={css`
                margin-top: ${vars.spacing[1]};
              `}
            >
              <Button theme="warning" flat slim block onClick={auth.logOut} loading={auth.loading ? 1 : 0}>
                Log out?
              </Button>
            </li>
            {!user.hasActiveSubscription && (
              <li
                css={css`
                  margin-top: ${vars.spacing[1]};
                `}
              >
                <Link href="/join">
                  <a>
                    <Button theme="success" flat slim block>
                      Subscribe
                    </Button>
                  </a>
                </Link>
              </li>
            )}
          </Menu>
        )}
      </AnimatePresence>
    </Root>
  );
};

export default NavUserInfo;

// Styles

const Root = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Menu = styled(motion.ul)`
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  top: 100%;
  right: ${vars.spacing[0]};
  margin-top: ${vars.spacing[0]};
  background: ${vars.colors.white};
  padding: ${vars.spacing[1]};
  color: ${vars.colors.black};
  border-radius: ${vars.borderRadius[1]};
  box-shadow: ${vars.shadows[0]};
  min-width: 180px;
  /* text-align: right; */
  /* font-size: 0.8rem; */
  li {
    white-space: nowrap;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -6px;
    right: 5px;
    height: 7px;
    width: 10px;
    /* background-color: white; */
    border-bottom: 7px solid white;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
`;

const Arrow = styled('div')`
  height: 8px;
  width: 10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid white;
`;
