import React from 'react';
import Router from 'next/router';
import { useMutation } from '@apollo/react-hooks';

import { LOG_IN_MUTATION, REGISTER_USER_MUTATION } from '../lib/graphql';
import { setTokens, unsetTokens } from '../lib/auth';

import { useError } from '../context/error';

function useAuth(ref, handler) {
  const [logInMutation, { error: logInError, loading: logInMutationLoading }] = useMutation(LOG_IN_MUTATION);
  const [registerUserMutation, { error: registerError, loading: registerMutationLoading }] = useMutation(REGISTER_USER_MUTATION);
  const [loading, setLoading] = React.useState(false);
  const { showErrorDialog } = useError();

  const logIn = async (username, password) => {
    if (!logInMutationLoading) {
      // clear cookies if there are any
      await unsetTokens();
      return await logInMutation({ variables: { input: { clientMutationId: 'logIn', username, password } } }).catch((err) => {
        process.browser && showErrorDialog(err.message);
      });
      console.log(res);
      return res;
      // if (res) {
      //   await setTokens(res.data.user);
      //   Router.reload();
      // } else {
      //   setLoading(false);
      // }
    }
  };

  const register = async (email, password, recaptcha) => {
    if (!registerMutationLoading) {
      const res = await registerUserMutation({
        variables: {
          input: {
            clientMutationId: 'registerUser',
            username: email,
            email,
            password,
            recaptcha,
          },
        },
      }).catch((err) => {
        showErrorDialog(err.message);
      });
      console.log(res);
      return res;
    }
  };

  const logOut = async () => {
    setLoading(true);
    await unsetTokens();
    window.localStorage.setItem('logout', Date.now());
    // await client.resetStore();
    Router.reload();
  };

  return { loading, logIn, logOut, register };
}

export default useAuth;
